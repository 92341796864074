
import {defineComponent} from "vue";

export default defineComponent({
  name: "wanted-invoice-email",
  setup() {
    const wantedInvoiceMail = "invoice@wantedint.com"
    return {
      wantedInvoiceMail
    }
  }
});


import { defineComponent } from "vue";
import SwiftIbanInputs from "@/components/users/userProfile/fields/paymentEditingFields/IbanSwift.vue";
import TaxIDInputs from "@/components/users/userProfile/fields/paymentEditingFields/TaxID.vue";
import DanishAccountInputs from "@/components/users/userProfile/fields/paymentEditingFields/DanishAccountInputs.vue";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  /* eslint-disable @typescript-eslint/camelcase */
  name: "user-payment-edit",
  components: {
    SwiftIbanInputs,
    TaxIDInputs,
    DanishAccountInputs
  },
  data() {
    return {
      takeDataFromChild: false,
      hasTaxId: false,
      hasDanishAccount: false,
      payload: {},
      isSaving: false
    };
  },
  mounted() {
    this.hasTaxId = this.getSingleUser.payment_settings.has_tax_id ? true : false;
    this.hasDanishAccount = this.getSingleUser.payment_settings
      .has_danish_bank ? true : false;
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  },
  methods: {
    ...mapActions("UsersModule", ["updatePayment"]),
    mutateChildProp() {
      this.takeDataFromChild = !this.takeDataFromChild;
    },
    saveData(dataFromInputs) {
      if (this.hasTaxId == true) {
        this.payload = {
          ...dataFromInputs,
          has_tax_id: this.hasTaxId
        };
      } else if (this.hasDanishAccount == true) {
        this.payload = {
          ...dataFromInputs,
          has_danish_bank: this.hasDanishAccount
        };
      } else {
        this.payload = {
          ...dataFromInputs
        };
      }
      this.isSaving = true;
      this.updatePayment({
        id: this.$route.params.id,
        payload: this.payload
      })
        .then(() => {
          this.isSaving = false;
        })
        .catch(error => {
          this.isSaving = false;
          if (error.data) {
            let errorString = "";
            Object.keys(error.data.errors).forEach(key => {
              errorString += error.data.errors[key] + "<br />";
            });
            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "error",
              title: errorString,
              showConfirmButton: false,
              timer: 1200
            });
          }
        });
      this.payload = {};
    }
  }
});

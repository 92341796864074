<template>
  <div class="card-body p-9">
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Social security</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.social_security"
          :placeholder="'Enter social security number'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Tax ID</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.tax_id"
          :placeholder="'Enter TAX ID'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Company name</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.company_name"
          :placeholder="'Enter company name'"
        ></InputField>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import InputField from "@/components/general/InputField.vue"
import { mapGetters } from "vuex";

export default defineComponent({
  name: "taxID-edit",
  components: {
    InputField
  },
  props:{
    emitData: Boolean
  },
  data() {
    return {
      data: {
        social_security: "",
        tax_id: "",
        company_name: ""
      }
    };
  },
  mounted() {
    this.data.social_security = this.getSingleUser.payment_settings.social_security;
    this.data.tax_id = this.getSingleUser.payment_settings.tax_id;
    this.data.company_name = this.getSingleUser.payment_settings.company_name;
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  },
  watch: {
    emitData() {
        this.$emit("saveData", this.data);
    }
  }
});
</script>

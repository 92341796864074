
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import WantedInvoiceEmail from "@/components/payments/misc/WantedInvoiceEmail.vue";

export default defineComponent({
  name: "tax-id-payment",
  props: {
    isAtOwnProfile: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    WantedInvoiceEmail
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  }
});

<template>
  <div class="card-body p-9">
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Social security</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.social_security"
          :placeholder="'Enter social security number'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Tax card</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.taxCard"
          :placeholder="'Only for danish residents'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Swift</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.swift"
          :placeholder="'Enter swift'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">IBAN</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.iban"
          :placeholder="'Enter Iban'"
        ></InputField>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import InputField from "@/components/general/InputField.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "iban-swift-edit",
  components: {
    InputField
  },
  props: {
    emitData: Boolean
  },
  data() {
    return {
      data: {
        social_security: "",
        iban: "",
        swift: "",
        taxCard: ""
      }
    };
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  },
  mounted() {
    const payment_settings = this.getSingleUser.payment_settings;
    if (payment_settings) {
      this.data.social_security = payment_settings.social_security;
      this.data.iban = payment_settings.iban;
      this.data.swift = payment_settings.swift;
      this.data.taxCard = payment_settings.tax_card;
    }
  },
  watch: {
    emitData() {
      this.$emit("saveData", this.data);
    }
  }
});
</script>

<template>
  <EditPaymentInfo
    v-if="isEdited"
    @discard="isEdited = false"
    @close="isEdited = false"
  ></EditPaymentInfo>
  <LoadingSpinner v-else-if="!isEdited && !hasData"></LoadingSpinner>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view" v-else>
    <div class="card-header cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Payment information - {{ accountType }}</h3>
      </div>
      <button
        @click="isEdited = true"
        v-if="
          $can('edit_user_profile_info', 'all') ||
            currentUser.id == $route.params.id
        "
        class="btn btn-sm btn-primary align-self-center"
      >
        Edit
      </button>
    </div>
    <TaxID
      :isAtOwnProfile="isAtOwnProfile"
      v-if="getSingleUser.payment_settings.has_tax_id == 1"
    ></TaxID>
    <DanishBankAccount
      :isAtOwnProfile="isAtOwnProfile"
      v-else-if="getSingleUser.payment_settings.has_danish_bank == 1"
    ></DanishBankAccount>
    <SwiftIban :isAtOwnProfile="isAtOwnProfile" v-else></SwiftIban>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditPaymentInfo from "@/components/users/userProfile/fields/EditPaymentInfo.vue";
import TaxID from "@/components/users/userProfile/fields/paymentViewFields/TaxId.vue";
import DanishBankAccount from "@/components/users/userProfile/fields/paymentViewFields/DanishBankAccount.vue";
import SwiftIban from "@/components/users/userProfile/fields/paymentViewFields/IbanSwift.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import _ from 'lodash';
import { mapGetters } from "vuex";

export default defineComponent({
  name: "user-payment",
  components: {
    EditPaymentInfo,
    LoadingSpinner,
    TaxID,
    DanishBankAccount,
    SwiftIban
  },
  data() {
    return {
      isEdited: false
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("Payment info", ["User"]);
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"]),
    ...mapGetters({ currentUser: "currentUser" }),
    hasData() {
      return !_.isEmpty(this.getSingleUser);
    },
    isAtOwnProfile() {
      if(this.currentUser && this.$route.params) {
        return this.currentUser.id == this.$route.params.id;
      }
      return false;
    },
    accountType() {
        if (this.getSingleUser.payment_settings.has_tax_id == 1) {
          return "TAX ID";
        } else if (this.getSingleUser.payment_settings.has_danish_bank == 1) {
          return "Danish Bank account";
        } else {
          return "SWIFT/IBAN";
        }
     }

  }
});
</script>

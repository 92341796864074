<template>
  <div class="card-body p-9">
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Social security</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.social_security"
          :placeholder="'Enter social security number'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">Tax card</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.tax_card"
          :placeholder="'Only for danish residents'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">REG NR</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.reg"
          :placeholder="'Enter swift'"
        ></InputField>
      </div>
    </div>
    <div class="row mb-7 align-items-center">
      <label class="col-lg-4 fw-bold text-muted">KONTRO NR</label>
      <div class="col-lg-4">
        <InputField
          v-model="data.konto"
          :placeholder="'Enter Iban'"
        ></InputField>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import InputField from "@/components/general/InputField.vue"
import { mapGetters } from "vuex";

export default defineComponent({
  name: "danish-bank-account-edit",
  components: {
    InputField
  },
  props:{
    emitData: Boolean
  },
  data() {
    return {
      data: {
        social_security: "",
        tax_card: "",
        reg: "",
        konto: ""
      }
    };
  },
  mounted() {
    this.data.social_security = this.getSingleUser.payment_settings.social_security;
    this.data.tax_card = this.getSingleUser.payment_settings.tax_card;
    this.data.reg = this.getSingleUser.payment_settings.reg;
    this.data.konto = this.getSingleUser.payment_settings.konto;
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  },
  watch: {
    emitData() {
        this.$emit("saveData", this.data);
    }
  }
});
</script>


import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "danish-account",
  props: {
    isAtOwnProfile: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  }
});
